import Vue from 'vue';

import {
  getItens as dadosItens,
  getItem as dadosItem,
  atualizarItem,
  deletarItem,
  deletarLoteItem,
  revogarItem,
  iniciarLance,
  iniciarPreferencia,
  adjudicarVencedorItens,
  homologarVencedorItens,
} from '@/resources/ofertasCompra';

import {
  deleteAuctionItems,
  importAuctionItems,
  createAuctionItem,
  createAuctionBatchItem,
  updateAuctionBatchItem,
  getAuctionItems,
} from '@/resources/electronicAuctions/auctionsItems';

const types = {
  ITEM: 'ITEM',
  ITENS: 'ITENS',
  ITENS_LIMPAR: 'ITENS_LIMPAR',
  ITEMS_AUCTION_IMPORT_UPLOAD: 'ITEMS_AUCTION_IMPORT_UPLOAD',
  ITEMS_AUCTION_IMPORT_RESET_UPLOAD: 'ITEMS_AUCTION_IMPORT_RESET_UPLOAD',
  ITEM_PREFERENCIA_COLLECTION: 'ITEM_PREFERENCIA_COLLECTION',
  ITEM_UPDATE: 'ITEM_UPDATE',
  ITEM_UPDATE_BUYER: 'ITEM_UPDATE_BUYER',
  ITEM_UPDATE_ESTADO: 'ITEM_UPDATE_ESTADO',
  ITEM_REMOVER: 'ITEM_REMOVER',
  ITEM_ADICIONAR: 'ITEM_ADICIONAR',
  ITEM_PROPOSTAS: 'ITEM_PROPOSTAS',
  ITEM_LOTE_ADICIONAR: 'ITEM_LOTE_ADICIONAR',
  ITEM_LOTE_ATUALIZAR: 'ITEM_LOTE_ATUALIZAR',
  LOTE_ITEM_REMOVER: 'LOTE_ITEM_REMOVER',
};

const mutations = {
  [types.ITENS](state, itens) {
    if (itens && Array.isArray(itens)) {
      state.itens = itens;
    }
  },
  [types.ITEM](state, item) {
    if (item && typeof item === 'object') {
      state.item = item;
    }
  },
  [types.ITEM_REMOVER](state, id) {
    const itemParaExcluir = state.itens.find(item => item.id === id);

    const index = state.itens.findIndex(item => item.id === id);
    if (index > -1) {
      state.itens.splice(index, 1);
    }

    state.itens.forEach((item, indice) => {
      if (item.identificador > itemParaExcluir.identificador) {
        const novoObjeto = state.itens[indice];
        novoObjeto.identificador -= 1;
        Vue.set(state.itens, indice, novoObjeto);
      }
    });
  },
  [types.LOTE_ITEM_REMOVER](state, { ofertaCompraItemId, ofertaCompraItemLoteId }) {
    const lote = state.itens.find(item => item.id === ofertaCompraItemId);
    const itemParaExcluir = lote.items.find(item => item.id === ofertaCompraItemLoteId);
    const index = lote.items.findIndex(item => item.id === ofertaCompraItemLoteId);
    if (index > -1) {
      lote.items.splice(index, 1);
    }
    lote.total -= itemParaExcluir.total;
    lote.items = lote.items.map(item => {
      if (item.identificador > itemParaExcluir.identificador) {
        return { ...item, identificador: item.identificador - 1 };
      }
      return item;
    });

    const indexLote = state.itens.indexOf(lote);
    Vue.set(state.itens, indexLote, lote);
  },
  [types.ITEM_ADICIONAR](state, item) {
    state.itens = [...state.itens, item];
  },
  [types.ITEM_UPDATE](state, itens) {
    if (itens && Array.isArray(itens) && itens.length) {
      while (itens.length) {
        const item = itens.shift();
        const index = state.itens.findIndex(elemento => elemento.id === item.id);
        if (index > -1) {
          delete item.referencia;
          delete item.total;
          const itemAtualizado = Object.assign(state.itens[index], item);
          Vue.set(state.itens, index, itemAtualizado);
        }
      }
    } else if (itens && typeof itens === 'object') {
      const index = state.itens.findIndex(elemento => elemento.id === itens.id);
      if (index > -1) {
        const itemClone = { ...itens };
        delete itemClone.referencia;
        delete itemClone.total;
        const item = Object.assign(state.itens[index], itemClone);
        Vue.set(state.itens, index, item);
      }
    }
  },
  [types.ITEM_LOTE_ADICIONAR](state, itemLote) {
    const index = state.itens.findIndex(elemento => elemento.id === itemLote.ofertaCompraItemId);
    if (index > -1) {
      const itemAtualizado = state.itens[index];
      itemAtualizado.total += itemLote.total;
      itemAtualizado.referencia += itemLote.total;
      itemAtualizado.items.push(itemLote);
      Vue.set(state.itens, index, itemAtualizado);
    }
  },
  [types.ITEM_LOTE_ATUALIZAR](state, itemLote) {
    const index = state.itens.findIndex(elemento => elemento.id === itemLote.ofertaCompraItemId);
    if (index !== -1) {
      const itemAtualizado = state.itens[index];
      const itemFilhoIndex = itemAtualizado.items.findIndex(item => item.id === itemLote.id);
      if (itemFilhoIndex !== -1) {
        itemAtualizado.items.splice(itemFilhoIndex, 1, itemLote);
      }
      const totalLote = state.itens[index].items.reduce((total, item) => total + item.total, 0);
      itemAtualizado.total = totalLote;
      itemAtualizado.referencia = totalLote;
      Vue.set(state.itens, index, itemAtualizado);
    }
  },
  [types.ITEM_UPDATE_BUYER](state, item) {
    if (item && typeof item === 'object') {
      const index = state.itens.findIndex(elemento => elemento.id === item.id);
      if (index > -1) {
        const itemAtualizado = Object.assign(state.itens[index], item);
        const itemsLote = JSON.parse(JSON.stringify(state.itens[index].items));
        if (itemsLote.length > 0) {
          itemAtualizado.items = itemsLote;
        }
        Vue.set(state.itens, index, itemAtualizado);
      }
    }
  },
  [types.ITEM_UPDATE_ESTADO](state, itens) {
    if (itens && Array.isArray(itens) && itens.length) {
      while (itens.length) {
        const item = itens.shift();
        const index = state.itens.findIndex(elemento => elemento.id === item.id);
        if (index > -1) {
          const novoObjeto = state.itens[index];
          novoObjeto.estado = item.estado;
          Vue.set(state.itens, index, novoObjeto);
        }
      }
    } else if (itens && typeof itens === 'object') {
      const index = state.itens.findIndex(elemento => elemento.id === itens.id);
      if (index > -1) {
        const novoObjeto = state.itens[index];
        novoObjeto.estado = itens.estado;
        Vue.set(state.itens, index, novoObjeto);
      }
    }
  },
  [types.ITENS_LIMPAR](state) {
    state.itens = [];
    state.item = {};
  },
  [types.ITEM_PREFERENCIA_COLLECTION]({ itens }) {
    for (let index = 0; index < itens.length; index += 1) {
      const item = itens[index];
      if (item.preferencia.valor !== null) {
        item.estado = 'PREFERENCIA';
      }
    }
  },
  [types.ITEM_PROPOSTAS](state, propostas) {
    const totalItens = propostas.reduce((itens, proposta) => {
      const item = itens.find(elemento => elemento.id === proposta.item_id);
      if (item) {
        item.quantidade += 1;
      } else {
        itens.push({
          id: proposta.item_id,
          quantidade: 1,
        });
      }
      return itens;
    }, []);
    totalItens.forEach(total => {
      const item = state.itens.find(elemento => elemento.id === total.id);
      if (item) item.propostas = total.quantidade;
    });
  },
  [types.ITEMS_AUCTION_IMPORT_UPLOAD](state, progress) {
    state.auctionImportItemsUpload = Math.round((progress.loaded / progress.total) * 100);
  },
  [types.ITEMS_AUCTION_IMPORT_RESET_UPLOAD](state) {
    state.auctionImportItemsUpload = 0;
  },
};

const actions = {
  async buscarItens({ commit }, { ofertaCompraId, listarItensLote = false, v2 = false }) {
    const response = await dadosItens(ofertaCompraId, listarItensLote, v2);
    if (response.status !== 200) return Promise.reject(response);
    return commit(types.ITENS, response.data.data);
  },
  async buscarOfertaCompraItem({ commit }, { ofertaCompraId, itemId }) {
    const { data: item } = await dadosItem(ofertaCompraId, itemId);
    commit(types.ITEM, item.data);
  },
  async atualizarItemComprador({ commit, rootState }, data) {
    const ofertaCompraId = rootState.ofertasCompra.ofertaCompra.id;
    const response = await atualizarItem(ofertaCompraId, data.ofertaCompraItemId, data);
    if (response.status !== 200) return Promise.reject(response);
    return commit(types.ITEM_UPDATE_BUYER, response.data.data);
  },
  async deleteAuctionItems({ commit }, { auctionId, auctionItemsId }) {
    const response = await deleteAuctionItems(auctionId, auctionItemsId);
    if (response.status !== 200) return Promise.reject(response);
    return commit(types.ITENS, response.data);
  },
  async createAuctionItem({ commit }, { auctionId, item }) {
    const response = await createAuctionItem(auctionId, item);
    if (response.status !== 201) return Promise.reject(response);
    return commit(types.ITEM_ADICIONAR, response.data.data);
  },
  async getAuctionItems({ commit }, auctionId) {
    const response = await getAuctionItems(auctionId);
    if (response.status !== 200) return Promise.reject(response);
    return commit(types.ITENS, response.data.data);
  },
  async createAuctionBatchItem({ commit }, { auctionId, auctionItemId, item }) {
    const response = await createAuctionBatchItem(auctionId, auctionItemId, item);
    if (response.status !== 201) return Promise.reject(response);
    return commit(types.ITEM_LOTE_ADICIONAR, response.data);
  },
  async updateAuctionBatchItem({ commit }, { auctionId, auctionItemId, auctionLoteItemId, item }) {
    const response = await updateAuctionBatchItem(
      auctionId,
      auctionItemId,
      auctionLoteItemId,
      item,
    );
    if (response.status !== 201) return Promise.reject(response);
    return commit(types.ITEM_LOTE_ATUALIZAR, response.data);
  },
  async removerItem({ commit, rootState }, ofertaCompraItemId) {
    const ofertaCompraId = rootState.ofertasCompra.ofertaCompra.id;
    const item = await deletarItem(ofertaCompraId, ofertaCompraItemId);
    if (item.status === 204) commit(types.ITEM_REMOVER, ofertaCompraItemId);
  },
  async removerLoteItem({ commit, rootState }, { ofertaCompraItemId, ofertaCompraItemLoteId }) {
    const ofertaCompraId = rootState.ofertasCompra.ofertaCompra.id;
    const item = await deletarLoteItem(ofertaCompraId, ofertaCompraItemId, ofertaCompraItemLoteId);
    if (item.status === 204)
      commit(types.LOTE_ITEM_REMOVER, { ofertaCompraItemId, ofertaCompraItemLoteId });
  },
  async revogarItem({ commit, rootState }, { ofertaCompraItemId, justificativa }) {
    const ofertaCompraId = rootState.ofertasCompra.ofertaCompra.id;
    const { data: item } = await revogarItem(ofertaCompraId, ofertaCompraItemId, { justificativa });
    commit(types.ITEM_UPDATE, item.data);
  },
  async adjudicarVencedorItens({ commit, rootState }, { itens, justificativa }) {
    const ofertaCompraId = rootState.ofertasCompra.ofertaCompra.id;
    const response = await adjudicarVencedorItens(ofertaCompraId, {
      auctionItems: itens,
      justification: justificativa,
    });
    if (response.status !== 200) return Promise.reject(response);
    return commit(types.ITEM_UPDATE, response.data);
  },
  async homologarVencedorItens({ commit, rootState }, { itens, justificativa }) {
    const ofertaCompraId = rootState.ofertasCompra.ofertaCompra.id;
    const response = await homologarVencedorItens(ofertaCompraId, {
      auctionItems: itens,
      justification: justificativa,
    });
    if (response.status !== 200) return Promise.reject(response);
    return commit(types.ITEM_UPDATE, response.data);
  },
  validarItensBeforePublish({ state, rootState }) {
    const tipoEdital = rootState.ofertasCompra.ofertaCompra.tipo_edital;
    if (!state.itens.length) {
      return {
        valid: false,
        errorMessage: 'Informe os itens do pregão eletrônico',
      };
    }

    if (
      tipoEdital === 'RESERVADO' &&
      state.itens.length &&
      !state.itens.every(({ item }) => item.exclusivo)
    ) {
      return {
        valid: false,
        errorMessage: 'Todos os Item/Lote precisam ser exclusivo',
      };
    }

    if (
      tipoEdital === 'AMPLO' &&
      state.itens.length &&
      state.itens.some(({ item }) => item.exclusivo)
    ) {
      return {
        valid: false,
        errorMessage: 'Tipo de edital não permite itens exclusivos',
      };
    }

    if (
      tipoEdital === 'AMPLOCOTA' &&
      state.itens.length &&
      !(
        state.itens.some(({ item }) => item.exclusivo) &&
        state.itens.some(({ item }) => !item.exclusivo)
      )
    ) {
      return {
        valid: false,
        errorMessage: 'Selecione o(s) item(s)/lotes para Cota Reservada ou Ampla',
      };
    }

    return { valid: true, errorMessage: '' };
  },
  limparItensPregao({ commit }) {
    commit(types.ITENS_LIMPAR);
  },
  atualizarItens({ commit }, itens) {
    commit(types.ITEM_UPDATE, itens);
  },
  atualizarItensEstado({ commit }, itens) {
    commit(types.ITEM_UPDATE_ESTADO, itens);
  },
  updateItemsAfterRemove({ commit }, items) {
    commit(types.ITENS, items);
  },
  setItemPreferenciaCollection({ commit }) {
    commit(types.ITEM_PREFERENCIA_COLLECTION);
  },
  async permitirLances({ rootState }, itens) {
    const response = await iniciarLance(rootState.ofertasCompra.ofertaCompra.id, { itens });
    const {
      status,
      data: { type },
    } = response;
    if (status !== 200) return Promise.reject(response);
    return status === 200 && type === 'IniciadoLances';
  },
  async permitirPreferencia({ rootState }, itens) {
    const response = await iniciarPreferencia(rootState.ofertasCompra.ofertaCompra.id, { itens });
    const {
      status,
      data: { type },
    } = response;
    if (response.status !== 200) return Promise.reject(response);
    return status === 200 && type === 'IniciadoPreferencia';
  },
  async importAuctionItems({ commit }, { auctionId, data }) {
    const response = await importAuctionItems(auctionId, data, {
      onUploadProgress: progress => commit(types.ITEMS_AUCTION_IMPORT_UPLOAD, progress),
    });
    if (response.status !== 201) return Promise.reject(response.data);
    return commit(types.ITENS, response.data);
  },
  resetAuctionImportItemsUpload({ commit }) {
    commit(types.ITEMS_AUCTION_IMPORT_RESET_UPLOAD);
  },
};

const getters = {
  obterItem(state) {
    return state.item;
  },
  obterItens(state, _, rootState) {
    return state.itens.filter(item => {
      const { ofertaCompra } = rootState.ofertasCompra;
      const situacao = ofertaCompra ? ofertaCompra.situacao.id : 0;
      const { proposals } = rootState.proposals;
      const bidder = rootState.auctionsBidders.userLoggedBidder;
      const { elegiveisFechado } = rootState.ofertasCompra;
      const { participantesFechado } = rootState.ofertasCompra;
      const etapaLances = rootState.prazos.prazos.some(elemento => elemento.prazo_id === 8);
      const propostaItem = proposals.findIndex(
        proposta => proposta.oferta_compra_item_id === item.id,
      );

      if (propostaItem > -1) {
        const propostasFiltradas = proposals.find(
          proposta =>
            proposta.fornecedor === bidder.alias &&
            proposta.oferta_compra_item_id === item.id &&
            proposta.estado === 'CLASSIFICADA',
        );

        const elegivelItem = elegiveisFechado.some(
          elemento => elemento.apelido === bidder.alias && elemento.item_id === item.id,
        );

        const participanteItem = participantesFechado.some(
          elemento => elemento.apelido === bidder.alias && elemento.itemId === item.id,
        );

        if (item.estado === 'REVOGADO') return false;

        if (
          situacao === 6 &&
          (item.estado === 'LANCE' || item.estado === 'LANCE-RANDOMICO') &&
          propostasFiltradas
        ) {
          return true;
        }

        if (situacao === 6 && item.estado === 'LANCE-FECHADO' && elegivelItem) {
          return true;
        }

        if (
          situacao === 7 &&
          item.estado === 'PREFERENCIA-INICIADA' &&
          item.preferencia.fornecedor === bidder.alias
        ) {
          return true;
        }

        if (
          situacao === 8 &&
          (ofertaCompra.modo_disputa === 'ABERTO' ||
            ofertaCompra.modo_disputa === 'FECHADO_ABERTO' ||
            !etapaLances) &&
          ['NEGOCIACAO', 'NEGOCIACAO-ENCERRADA'].indexOf(item.estado) > -1 &&
          item.menor_lance.fornecedor === bidder.alias
        ) {
          return true;
        }
        if (
          situacao === 8 &&
          ofertaCompra.modo_disputa === 'FECHADO' &&
          ['NEGOCIACAO', 'NEGOCIACAO-ENCERRADA'].indexOf(item.estado) > -1 &&
          item.menor_lance.fornecedor === bidder.alias &&
          participanteItem
        ) {
          return true;
        }

        if (
          situacao === 9 &&
          (ofertaCompra.modo_disputa === 'ABERTO' ||
            ofertaCompra.modo_disputa === 'FECHADO_ABERTO' ||
            !etapaLances) &&
          ['HABILITACAO', 'HABILITACAO-ENCERRADA'].indexOf(item.estado) > -1 &&
          item.menor_lance.fornecedor === bidder.alias
        ) {
          return true;
        }
        if (
          situacao === 9 &&
          ofertaCompra.modo_disputa === 'FECHADO' &&
          ['HABILITACAO', 'HABILITACAO-ENCERRADA'].indexOf(item.estado) > -1 &&
          item.menor_lance.fornecedor === bidder.alias &&
          participanteItem
        ) {
          return true;
        }

        return false;
      }
      return false;
    });
  },
  obterTodosItens(state, _, rootState) {
    return state.itens.map(item => {
      const propostas = rootState.proposals.proposals.filter(
        proposal =>
          proposal.oferta_compra_item_id === item.id &&
          ['CLASSIFICAR', 'EMPATADA'].includes(proposal.estado),
      );
      return {
        ...item,
        propostasAClassificar: propostas.length > 0,
      };
    });
  },
  getAuctionImportItemsUpload(state) {
    return state.auctionImportItemsUpload;
  },
};

const state = {
  item: {
    menor_lance: {},
    preferencia: {},
  },
  itens: [],
  auctionImportItemsUpload: 0,
};

export default {
  state,
  mutations,
  actions,
  getters,
};

export { types };
