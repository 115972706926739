<template>
  <div>
    <b-loading is-full-page :active="loading.general" can-cancel />

    <form @submit.prevent="salvarDados">
      <b-field grouped class="column-direction-touch">
        <b-field
          expanded
          label="Número do Processo"
          :type="campoInvalido('numeroProcesso') ? 'is-danger' : ''"
          :message="
            campoInvalido('numeroProcesso') ? 'Número do Processo é um campo obrigatório' : ''
          "
        >
          <b-input
            custom-class="uppercase"
            placeholder="Número do Processo"
            v-model="$v.dados.numeroProcesso.$model"
            @blur="evento => converterMaiusculo('numeroProcesso', evento.target.value)"
          />
        </b-field>

        <b-field
          expanded
          label="Número do Pregão Eletrônico"
          :type="campoInvalido('numeroOC') ? 'is-danger' : ''"
          :message="
            campoInvalido('numeroOC') ? 'Número do pregão eletrônico é um campo obrigatório' : ''
          "
        >
          <b-input
            custom-class="uppercase"
            placeholder="Número do Pregão Eletrônico"
            v-model="$v.dados.numeroOC.$model"
            @blur="evento => converterMaiusculo('numeroOC', evento.target.value)"
          />
        </b-field>

        <b-field
          expanded
          label="Tipo de Pregão Eletrônico"
          :type="campoInvalido('tipoPregao') ? 'is-danger' : ''"
          :message="campoInvalido('tipoPregao') ? 'Selecione um tipo de Pregão Eletrônico' : ''"
        >
          <b-select
            expanded
            placeholder="Tipo de Pregão Eletrônico"
            v-model="$v.dados.tipoPregao.$model"
            @click.native="$v.dados.tipoPregao.$touch()"
          >
            <option v-for="tipoPregao in tiposPregao" :key="tipoPregao.id" :value="tipoPregao.enum">
              {{ tipoPregao.nome }}
            </option>
          </b-select>
        </b-field>

        <b-field
          label="Modo de Disputa"
          :type="campoInvalido('modoDisputa') ? 'is-danger' : ''"
          :message="campoInvalido('modoDisputa') ? 'Selecione um modo de Disputa' : ''"
        >
          <b-select
            expanded
            placeholder="Modo de Disputa"
            v-model="$v.dados.modoDisputa.$model"
            @click.native="$v.dados.modoDisputa.$touch()"
          >
            <option
              v-for="modoDisputa in modosDisputa"
              :key="modoDisputa.id"
              :value="modoDisputa.enum"
            >
              {{ modoDisputa.nome }}
            </option>
          </b-select>
        </b-field>
      </b-field>

      <b-field expanded grouped class="column-direction-touch">
        <b-field
          expanded
          label="Tipo de Negociação"
          :type="campoInvalido('tipoNegociacaoId') ? 'is-danger' : ''"
          :message="campoInvalido('tipoNegociacaoId') ? 'Selecione um tipo de Negociação' : ''"
        >
          <b-select
            expanded
            placeholder="Tipo de Negociação"
            v-model="$v.dados.tipoNegociacaoId.$model"
            @click.native="$v.dados.tipoNegociacaoId.$touch()"
            @input.native="event => checkHighestDiscount(event.target.value)"
          >
            <option
              v-for="tipoNegociacao in tiposNegociacao"
              :key="tipoNegociacao.id"
              :value="tipoNegociacao.id"
            >
              {{ tipoNegociacao.nome }}
            </option>
          </b-select>
        </b-field>

        <b-field
          label="Tipo de Edital"
          :type="campoInvalido('tipoEdital') ? 'is-danger' : ''"
          :message="campoInvalido('tipoEdital') ? 'Selecione um tipo de Edital' : ''"
        >
          <b-select
            placeholder="Tipo de Edital"
            v-model="$v.dados.tipoEdital.$model"
            @click.native="$v.dados.tipoEdital.$touch()"
            @input="value => changeRegionalPreference(value)"
          >
            <option
              v-for="tipoEdital in tiposEditalFiltrado"
              :key="tipoEdital.id"
              :value="tipoEdital.enum"
            >
              {{ tipoEdital.nome }}
            </option>
          </b-select>
        </b-field>

        <b-field
          expanded
          label="Objeto"
          :type="campoInvalido('objeto') ? 'is-danger' : ''"
          :message="campoInvalido('objeto') ? 'Objeto é um campo obrigatório' : ''"
        >
          <b-input
            custom-class="uppercase"
            placeholder="Objeto"
            v-model="$v.dados.objeto.$model"
            @blur="event => converterMaiusculo('objeto', event.target.value)"
          />
        </b-field>
      </b-field>

      <b-field grouped class="column-direction-touch">
        <b-field
          expanded
          class="has-text-centered regional-preference"
          label="Preferência Local/Regional"
          v-if="dados.tipoEdital === 'RESERVADO'"
        >
          <b-switch
            size="is-medium"
            v-model="dados.preferenciaRegional"
            @input="displayPreferenceWarning()"
            >{{ dados.preferenciaRegional ? 'Sim' : 'Não' }}
          </b-switch>
        </b-field>

        <b-field expanded class="has-text-centered reference-disclose" label="Divulgar Referencial">
          <b-switch
            size="is-medium"
            :disabled="dados.tipoNegociacaoId === 3"
            v-model="dados.divulgarReferencial"
            @input="exibirAvisoReferencial()"
            >{{ dados.divulgarReferencial ? 'Sim' : 'Não' }}
          </b-switch>
        </b-field>

        <b-field
          expanded
          label="Data de Publicação"
          :type="campoInvalido('dataPublicacao') ? 'is-danger' : ''"
          :message="
            campoInvalido('dataPublicacao') ? 'Selecione uma data válida para publicação' : ''
          "
        >
          <b-datepicker
            editable
            placeholder="Data de Publicação"
            icon="calendar-today"
            v-mascara:data
            :max-date="maiorDataPublicacao"
            :min-date="menorDataPublicacao"
            :unselectable-days-of-week="diasNaoSelecionaveis"
            :unselectable-dates="feriadosNacionais"
            v-model="valoresMascara.dataPublicacao"
            @input="valor => atualizarDataPersonalizado('dataPublicacao', valor)"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          >
            <button
              class="button is-primary br-4 mr-1"
              @click.prevent.stop="inserirHoje('dataPublicacao')"
            >
              <b-icon icon="calendar-today" />
              <span>Hoje</span>
            </button>

            <button
              class="button is-danger br-4"
              @click.prevent.stop="limparData('dataPublicacao')"
            >
              <b-icon icon="close" />
              <span>Limpar</span>
            </button>
          </b-datepicker>
        </b-field>

        <b-field
          expanded
          label="Data de Abertura"
          :type="campoInvalido('dataAbertura') ? 'is-danger' : ''"
          :message="campoInvalido('dataAbertura') ? 'Selecione uma data válida para abertura' : ''"
        >
          <b-datepicker
            editable
            placeholder="Data de Abertura"
            icon="calendar-today"
            v-mascara:data
            :min-date="menorDataAbertura"
            :unselectable-days-of-week="diasNaoSelecionaveis"
            :unselectable-dates="feriadosNacionais"
            v-model="valoresMascara.dataAbertura"
            @input="valor => atualizarDataPersonalizado('dataAbertura', valor)"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          >
            <button class="button is-danger" @click.prevent.stop="limparData('dataAbertura')">
              <b-icon icon="close" />
              <span>Limpar</span>
            </button>
          </b-datepicker>
        </b-field>

        <b-field
          expanded
          label="Horário"
          :type="campoInvalido('horaAbertura') ? 'is-danger' : ''"
          :message="campoInvalido('horaAbertura') ? 'Selecione uma hora válida para abertura' : ''"
        >
          <b-timepicker
            editable
            ref="hora"
            maxlength="5"
            placeholder="00:00"
            icon="clock"
            v-mascara:hora
            v-model="valoresMascara.horaAbertura"
            @input="valor => atualizarHora('horaAbertura', valor)"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          />
        </b-field>
      </b-field>

      <hr />

      <b-field grouped class="column-direction-touch">
        <b-field
          label="CEP"
          :type="campoInvalido('enderecoEntregaCEP') ? 'is-danger' : ''"
          :message="campoInvalido('enderecoEntregaCEP') ? 'É necessario preencher o CEP' : ''"
        >
          <b-input
            placeholder="CEP"
            maxlength="9"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            :loading="pesquisando"
            :has-counter="false"
            @blur="buscarCEP"
            @keyup.native.enter.prevent.stop="buscarCEP"
            v-mascara:cep
            v-model="$v.dados.enderecoEntregaCEP.$model"
          />
        </b-field>

        <b-field
          expanded
          label="Endereço de Entrega"
          :type="campoInvalido('enderecoEntrega') ? 'is-danger' : ''"
          :message="campoInvalido('enderecoEntrega') ? 'Endereço é um campo obrigatório' : ''"
        >
          <b-input placeholder="Endereço" v-model="$v.dados.enderecoEntrega.$model" />
        </b-field>
      </b-field>

      <b-field grouped class="column-direction-touch">
        <b-field
          label="Número"
          :type="campoInvalido('enderecoEntregaNumero') ? 'is-danger' : ''"
          :message="campoInvalido('enderecoEntregaNumero') ? 'Número é um campo obrigatório' : ''"
        >
          <b-input
            placeholder="Número"
            v-model="$v.dados.enderecoEntregaNumero.$model"
            @input="$v.dados.enderecoEntregaNumero.$touch()"
          />
        </b-field>

        <b-field label="Complemento">
          <b-input
            custom-class="uppercase"
            placeholder="Complemento"
            v-model="$v.dados.enderecoEntregaComplemento.$model"
            @blur="evento => converterMaiusculo('enderecoEntregaComplemento', evento.target.value)"
          />
        </b-field>
      </b-field>

      <b-field grouped class="column-direction-touch">
        <b-field
          expanded
          label="Bairro"
          :type="campoInvalido('enderecoEntregaBairro') ? 'is-danger' : ''"
          :message="campoInvalido('enderecoEntregaBairro') ? 'Bairro é um campo obrigatório' : ''"
        >
          <b-input placeholder="Bairro" v-model="$v.dados.enderecoEntregaBairro.$model" />
        </b-field>

        <b-field
          expanded
          label="Município"
          :type="campoInvalido('enderecoEntregaMunicipio') ? 'is-danger' : ''"
          :message="campoInvalido('enderecoEntregaMunicipio') ? 'Preencha o munícipio' : ''"
        >
          <b-input placeholder="Município" v-model="$v.dados.enderecoEntregaMunicipio.$model" />
        </b-field>

        <b-field
          expanded
          label="UF"
          :type="campoInvalido('enderecoEntregaUF') ? 'is-danger' : ''"
          :message="campoInvalido('enderecoEntregaUF') ? 'O campo UF deve ser preenchido' : ''"
        >
          <b-select
            expanded
            placeholder="UF"
            v-model="$v.dados.enderecoEntregaUF.$model"
            @click.native="$v.dados.enderecoEntregaUF.$touch()"
          >
            <option v-for="option in ufOptions" :key="option.id" :value="option.value">
              {{ option.name }}
            </option>
          </b-select>
        </b-field>
      </b-field>

      <hr />

      <b-field
        expanded
        class="column-direction-touch"
        label="Origem dos Recursos"
        :type="campoInvalido('origemRecursos') ? 'is-danger' : ''"
        :message="campoInvalido('origemRecursos') ? 'Origem dos recursos deve ser preenchido' : ''"
      >
        <b-taginput
          autocomplete
          allow-new
          attached
          open-on-focus
          placeholder="Origem dos Recursos"
          :data="origens"
          v-model="$v.dados.origemRecursos.$model"
        />
      </b-field>

      <b-field grouped class="column-direction-touch">
        <b-field
          expanded
          label="Tipo da Origem dos Recursos"
          :type="campoInvalido('tipoEdital') ? 'is-danger' : ''"
          :message="campoInvalido('tipoEdital') ? 'Selecione um tipo da Origem dos Recursos' : ''"
        >
          <b-select
            expanded
            placeholder="Tipo da Origem dos Recursos"
            v-model="$v.dados.tipoOrigemRecursos.$model"
            @click.native="$v.dados.tipoOrigemRecursos.$touch()"
          >
            <option
              v-for="tipoOrigemRecursos in tiposOrigemRecursos"
              :key="tipoOrigemRecursos.id"
              :value="tipoOrigemRecursos.enum"
            >
              {{ tipoOrigemRecursos.nome }}
            </option>
          </b-select>
        </b-field>

        <b-field expanded label="Número do Instrumento (Convênio)">
          <b-input
            placeholder="Número do Instrumento"
            v-model="$v.dados.numeroInstrumento.$model"
          />
        </b-field>

        <b-field
          expanded
          label="Ano do Instrumento (Convênio)"
          :type="campoInvalido('anoInstrumento') ? 'is-danger' : ''"
          :message="campoInvalido('anoInstrumento') ? 'Informe um ano entre 1900 e 2099' : ''"
        >
          <b-input
            placeholder="Ano do Instrumento"
            v-mascara:valor.integer
            v-model="valoresMascara.anoInstrumento"
            @input.native="evento => atualizarValor('anoInstrumento', evento)"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          />
        </b-field>
      </b-field>

      <b-field class="column-direction-touch">
        <button
          class="button is-primary is-fullwidth save-button"
          type="submit"
          :class="{ ' is-loading': loading.salvar }"
          @click.prevent.stop="salvarDados"
        >
          Salvar
        </button>
      </b-field>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { required, requiredIf, minValue, between } from 'vuelidate/lib/validators';
import Holidays from 'date-holidays';

import campoMixin from '@/mixins/formulario';
import { dadosCEP } from '@/resources/utils';
import { ufOptions } from '@/utils/data';

export default {
  name: 'PregaoEditarDados',
  mixins: [campoMixin],
  data() {
    const hd = new Holidays('BR');
    const diasNaoSelecionaveis = [0, 6];
    const feriadosNacionais = [];
    const tiposPregao = [
      { id: 1, nome: 'Contratação Imediata', enum: 'IMEDIATO' },
      {
        id: 2,
        nome: 'Registro de Preços',
        enum: 'REGISTRO',
      },
    ];
    const tiposNegociacao = [
      { id: 1, nome: 'Valor Unitário' },
      { id: 2, nome: 'Valor Total' },
      { id: 3, nome: 'Maior Desconto' },
    ];
    const modosDisputa = [
      { id: 1, nome: 'Aberto', enum: 'ABERTO' },
      { id: 2, nome: 'Aberto e Fechado', enum: 'FECHADO' },
      { id: 3, nome: 'Fechado e Aberto', enum: 'FECHADO_ABERTO' },
    ];
    const tiposEdital = [
      { id: 1, nome: 'Participação Ampla', enum: 'AMPLO' },
      {
        id: 2,
        nome: 'Com Quota Reservada para ME/EPP/MEI/Cooperativa Preferência',
        enum: 'AMPLOCOTA',
      },
      {
        id: 3,
        nome: 'Participação Exclusiva de ME/EPP/MEI/Cooperativa Preferência',
        enum: 'RESERVADO',
      },
    ];
    const tiposOrigemRecursos = [
      { id: 1, nome: 'Nacional', enum: 'NACIONAL' },
      { id: 2, nome: 'Internacional', enum: 'INTERNACIONAL' },
    ];
    const origens = ['Transferências Voluntárias da União', 'Própria', 'Outras'];
    const dados = {
      tipo: 'PREGAO',
      numeroOC: '',
      numeroProcesso: null,
      objeto: '',
      origemRecursos: [],
      tipoOrigemRecursos: null,
      numeroInstrumento: null,
      anoInstrumento: null,
      tipoNegociacaoId: null,
      dataPublicacao: null,
      dataAbertura: null,
      horaAbertura: null,
      tipoPregao: null,
      modoDisputa: null,
      tipoEdital: null,
      preferenciaRegional: null,
      divulgarReferencial: null,
      enderecoEntregaCEP: null,
      enderecoEntrega: null,
      enderecoEntregaNumero: null,
      enderecoEntregaComplemento: null,
      enderecoEntregaBairro: null,
      enderecoEntregaMunicipio: null,
      enderecoEntregaUF: null,
    };
    const valoresMascara = {
      dataPublicacao: null,
      dataAbertura: null,
      horaAbertura: null,
      anoInstrumento: null,
    };

    const loading = {
      general: false,
      salvar: false,
    };

    return {
      diasNaoSelecionaveis,
      tiposPregao,
      tiposNegociacao,
      modosDisputa,
      tiposEdital,
      tiposOrigemRecursos,
      dados,
      valoresMascara,
      ufOptions,
      origens,
      pesquisando: false,
      feriados: hd
        .getHolidays(new Date().getFullYear())
        .concat(hd.getHolidays(new Date().getFullYear() + 1)),
      feriadosNacionais,
      loading,
      dadosMontados: false,
    };
  },
  async beforeRouteLeave(to, from, next) {
    if (this.logged && this.$v.dados.$anyDirty) {
      const sair = await this.$confirmacao({
        message: 'Os dados serão perdidos. Tem certeza que deseja continuar?',
      });
      return next(sair);
    }
    return next();
  },
  validations: {
    dados: {
      tipo: { required: false },
      numeroOC: { required },
      numeroProcesso: {
        required: requiredIf(function validarEmPublicacao() {
          return this.emPublicacao;
        }),
      },
      objeto: {
        required: requiredIf(function validarEmPublicacao() {
          return this.emPublicacao;
        }),
      },
      origemRecursos: {
        required: requiredIf(function validarEmPublicacao() {
          return this.emPublicacao;
        }),
      },
      tipoOrigemRecursos: {
        required: requiredIf(function validarEmPublicacao() {
          return this.emPublicacao;
        }),
      },
      numeroInstrumento: {
        required: false,
      },
      anoInstrumento: {
        required: false,
        between: between(1900, 2099),
      },
      tipoNegociacaoId: {
        required: requiredIf(function validarEmPublicacao() {
          return this.emPublicacao;
        }),
        minValue: minValue(1),
      },
      dataPublicacao: {
        required: requiredIf(function validarEmPublicacao() {
          return this.emPublicacao;
        }),
        valorMaximo() {
          if (this.dados.dataAbertura && this.dados.dataPublicacao > this.maiorDataPublicacao) {
            return false;
          }
          return true;
        },
      },
      dataAbertura: {
        required: requiredIf(function validarEmPublicacao() {
          return this.emPublicacao;
        }),
        valorMinimo() {
          if (this.dados.dataPublicacao && this.dados.dataAbertura < this.menorDataAbertura) {
            return false;
          }
          return true;
        },
      },
      horaAbertura: {
        required: requiredIf(function validarEmPublicacao() {
          return this.emPublicacao;
        }),
      },
      tipoPregao: {
        required: requiredIf(function validarEmPublicacao() {
          return this.emPublicacao;
        }),
      },
      modoDisputa: {
        required: requiredIf(function validarEmPublicacao() {
          return this.emPublicacao;
        }),
      },
      tipoEdital: {
        required: requiredIf(function validarEmPublicacao() {
          return this.emPublicacao;
        }),
      },
      enderecoEntregaCEP: {
        required: requiredIf(function validarEmPublicacao() {
          return this.emPublicacao;
        }),
      },
      enderecoEntrega: {
        required: requiredIf(function validarEmPublicacao() {
          return this.emPublicacao;
        }),
      },
      enderecoEntregaNumero: {
        required: requiredIf(function validarEmPublicacao() {
          return this.emPublicacao;
        }),
      },
      enderecoEntregaComplemento: { required: false },
      enderecoEntregaBairro: {
        required: requiredIf(function validarEmPublicacao() {
          return this.emPublicacao;
        }),
      },
      enderecoEntregaMunicipio: {
        required: requiredIf(function validarEmPublicacao() {
          return this.emPublicacao;
        }),
      },
      enderecoEntregaUF: {
        required: requiredIf(function validarEmPublicacao() {
          return this.emPublicacao;
        }),
      },
    },
  },
  computed: {
    ...mapGetters({
      getOfertaCompra: 'obterOfertaCompra',
      emPublicacao: 'obterEmPublicacaoPregao',
      logged: 'getLogged',
    }),
    ...mapGetters({
      auction: 'obterOfertaCompra',
      function: 'getFunction',
    }),
    horaAbertura: {
      get() {
        return this.dados.horaAbertura;
      },
      set(hora) {
        this.dados.horaAbertura = hora;
        if (this.dados.dataAbertura) {
          this.setHora(hora);
        }
      },
    },
    tiposEditalFiltrado() {
      if (this.auction?.situacao?.id > 1 && this.auction?.tipo_edital !== 'AMPLOCOTA') {
        return this.tiposEdital.filter(item => item.enum !== 'AMPLOCOTA');
      }
      return this.tiposEdital;
    },
    maiorDesconto() {
      return this.getOfertaCompra.tipo_negociacao.id === 3;
    },
    menorDataPublicacao() {
      if (process.env.VUE_APP_ENABLE_RETROACTIVE_DATE === 'true') {
        return null;
      }
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      return yesterday;
    },
    maiorDataPublicacao() {
      if (this.dados.dataAbertura) {
        const data = new Date(this.dados.dataAbertura);
        let diasUteis = 7;

        data.setDate(data.getDate() - 1);

        while (diasUteis > 0) {
          const feriado = this.feriadosNacionais.find(
            dataFeriado => dataFeriado.toDateString() === data.toDateString(),
          );
          const dia = data.getDay();
          if (dia === 0) {
            data.setDate(data.getDate() - 2);
          } else if (dia === 6 || feriado) {
            data.setDate(data.getDate() - 1);
          } else {
            data.setDate(data.getDate() - 1);
            diasUteis -= 1;
          }
        }
        const diaCalculado = data.getDay();
        if (diaCalculado === 6) {
          data.setDate(data.getDate() - 2);
        }
        if (diaCalculado === 0) {
          data.setDate(data.getDate() - 1);
        }

        const proximoDiaFeriado = this.feriadosNacionais.find(
          dataFeriado => dataFeriado.toDateString() === data.toDateString(),
        );
        if (proximoDiaFeriado) {
          data.setDate(data.getDate() - 1);
        }

        return data;
      }
      return null;
    },
    menorDataAbertura() {
      if (this.dados.dataPublicacao) {
        const data = new Date(this.dados.dataPublicacao);
        let diasUteis = 0;

        data.setDate(data.getDate() + 1);

        while (diasUteis < 7) {
          const feriado = this.feriadosNacionais.find(
            dataFeriado => dataFeriado.toDateString() === data.toDateString(),
          );
          const dia = data.getDay();
          if (dia === 0 || feriado) {
            data.setDate(data.getDate() + 1);
          } else if (dia === 6) {
            data.setDate(data.getDate() + 2);
          } else {
            data.setDate(data.getDate() + 1);
            diasUteis += 1;
          }
        }
        const diaCalculado = data.getDay();
        if (diaCalculado === 6) {
          data.setDate(data.getDate() + 2);
        }
        if (diaCalculado === 0) {
          data.setDate(data.getDate() + 1);
        }

        const proximoDiaFeriado = this.feriadosNacionais.find(
          dataFeriado => dataFeriado.toDateString() === data.toDateString(),
        );
        if (proximoDiaFeriado) {
          data.setDate(data.getDate() + 1);
        }

        return data;
      }
      return null;
    },
  },
  watch: {
    emPublicacao() {
      if (
        this.emPublicacao &&
        this.$route.name === 'PregaoEditarDados' &&
        this.getOfertaCompra.id &&
        this.dadosMontados
      ) {
        this.exibirRequeridos();
      }
    },
  },
  methods: {
    ...mapActions([
      'buscarItens',
      'alternarEmPublicacaoPregao',
      'buscarOfertaCompra',
      'editAuction',
    ]),
    checkHighestDiscount(option) {
      if (Number(option) === 3) this.dados.divulgarReferencial = true;
    },
    changeRegionalPreference(value) {
      if (['AMPLO', 'AMPLOCOTA'].indexOf(value) > -1) {
        this.dados.preferenciaRegional = false;
      }
    },
    async displayPreferenceWarning() {
      if (this.dados.preferenciaRegional) {
        const confirmation = await this.$confirmacao({
          message:
            '<strong>Atenção!</strong><br><br>Você está habilitando a opção de preferência local/regional. Deseja confirmar?',
        });

        if (!confirmation) {
          this.dados.preferenciaRegional = false;
        }
      }
    },
    async exibirAvisoReferencial() {
      const maiorDesconto = this.dados.tipoNegociacaoId === 3;

      if (this.dados.divulgarReferencial && !maiorDesconto) {
        const confirmacao = await this.$confirmacao({
          message:
            '<strong>Atenção!</strong><br><br>Você está habilitando a divulgação de referenciais dos itens a partir da etapa de negociação. Deseja confirmar?',
        });

        if (!confirmacao) {
          this.dados.divulgarReferencial = false;
        }
      }
    },
    async salvarDados() {
      const dadosRequisicao = { ...this.dados };

      Object.keys(dadosRequisicao).forEach(key => {
        if (dadosRequisicao[key] === '') {
          dadosRequisicao[key] = null;
        }
      });

      this.loading.salvar = true;
      try {
        await this.editAuction(dadosRequisicao);
        this.$v.dados.$reset();
        this.$alerta('Dados salvos', 'is-success');
      } catch (error) {
        const errorsItens = [
          'Todos os Item/Lote precisam ser exclusivo',
          'Selecione o(s) item(s)/lotes para Cota Reservada ou Ampla',
          'Tipo de edital não permite itens exclusivos',
        ];
        if (errorsItens.includes(error.response?.data?.error)) {
          this.$v.dados.$reset();
          this.$router.push({ name: 'PregaoEditarItens' });
          this.$alerta(error.response?.data?.error, 'is-danger');
        } else {
          this.$alerta('Erro ao salvar dados', 'is-danger');
        }
      }
      this.loading.salvar = false;
      this.alternarEmPublicacaoPregao(false);
    },
    exibirRequeridos() {
      if (this.$v.dados.$invalid) {
        Object.keys(this.$v.dados).forEach(key => {
          if (this.$v.dados[key].$invalid) {
            this.$v.dados[key].$touch();
          }
        });
      } else {
        this.$v.dados.$reset();
      }
    },
    montarOfertaCompra() {
      this.dados.tipo = this.getOfertaCompra.tipo;
      this.dados.numeroOC = this.getOfertaCompra.numero_oc;
      this.dados.numeroProcesso = this.getOfertaCompra.numero_processo;
      this.dados.objeto = this.getOfertaCompra.objeto;
      this.dados.origemRecursos = [...this.getOfertaCompra.origem_recursos];
      this.dados.tipoOrigemRecursos = this.getOfertaCompra.tipo_origem_recursos;
      this.dados.numeroInstrumento = this.getOfertaCompra.numero_instrumento;
      this.dados.anoInstrumento = this.getOfertaCompra.ano_instrumento;
      this.valoresMascara.anoInstrumento = this.getOfertaCompra.ano_instrumento;
      this.dados.tipoNegociacaoId = this.getOfertaCompra.tipo_negociacao
        ? this.getOfertaCompra.tipo_negociacao.id
        : null;
      this.valoresMascara.dataPublicacao = this.getOfertaCompra.data_publicacao;
      this.dados.dataPublicacao = this.getOfertaCompra.data_publicacao;
      this.valoresMascara.dataAbertura = this.getOfertaCompra.data_abertura;
      this.dados.dataAbertura = this.getOfertaCompra.data_abertura;
      this.valoresMascara.horaAbertura = this.getOfertaCompra.data_abertura;
      this.dados.horaAbertura = this.getOfertaCompra.data_abertura;
      this.dados.tipoPregao = this.getOfertaCompra.tipo_pregao;
      this.dados.modoDisputa = this.getOfertaCompra.modo_disputa;
      this.dados.tipoEdital = this.getOfertaCompra.tipo_edital;
      this.dados.preferenciaRegional = this.getOfertaCompra.preferencia_regional;
      this.dados.divulgarReferencial = this.getOfertaCompra.divulgar_referencial;
      this.dados.enderecoEntregaCEP = this.getOfertaCompra.endereco_entrega_cep;
      this.dados.enderecoEntrega = this.getOfertaCompra.endereco_entrega;
      this.dados.enderecoEntregaNumero = this.getOfertaCompra.endereco_entrega_numero;
      this.dados.enderecoEntregaComplemento = this.getOfertaCompra.endereco_entrega_complemento;
      this.dados.enderecoEntregaBairro = this.getOfertaCompra.endereco_entrega_bairro;
      this.dados.enderecoEntregaMunicipio = this.getOfertaCompra.endereco_entrega_municipio;
      this.dados.enderecoEntregaUF = this.getOfertaCompra.endereco_entrega_uf;

      this.dadosMontados = true;
    },
    async buscarCEP() {
      if (this.dados.enderecoEntregaCEP.trim()) {
        this.pesquisando = true;
        try {
          const {
            data: { data: response },
          } = await dadosCEP(this.dados.enderecoEntregaCEP);
          this.dados.enderecoEntrega = response.address;
          this.dados.enderecoEntregaBairro = response.neighborhood;
          this.dados.enderecoEntregaMunicipio = response.city;
          this.dados.enderecoEntregaUF = response.state;
        } catch (error) {
          this.$alerta(error.response.data.error, 'is-danger', 3000);
        }
        this.pesquisando = false;
      }
    },
    atualizarHora(campo, valor) {
      if (this.valoresMascara[campo] !== this.dados[campo]) {
        this.atualizarDado(campo, valor);
      }
      if (this.dados.dataAbertura && valor) {
        this.dados.dataAbertura.setHours(valor.getHours());
        this.dados.dataAbertura.setMinutes(valor.getMinutes());
      }
    },
    atualizarDataPersonalizado(campo, valor) {
      if (this.valoresMascara[campo] !== this.dados[campo]) {
        if (valor) {
          const dia = valor.getDay();

          if ([0, 6].indexOf(dia) > -1) {
            this.dados[campo] = null;
            this.valoresMascara[campo] = null;
            this.$alerta('Finais de semana não são permitidos', 'is-danger', 4000);
            return;
          }

          const feriado = this.feriadosNacionais.some(
            elemento => elemento.toString() === valor.toString(),
          );
          if (feriado) {
            this.dados[campo] = null;
            this.valoresMascara[campo] = null;
            this.$alerta('Feriados não são permitidos', 'is-danger', 4000);
            return;
          }

          if (
            campo === 'dataPublicacao' &&
            this.dados.dataAbertura &&
            valor > this.maiorDataPublicacao
          ) {
            this.dados[campo] = null;
            this.valoresMascara[campo] = null;
            this.$alerta('Data de publicação fora do prazo', 'is-danger', 4000);
            return;
          }

          if (
            campo === 'dataAbertura' &&
            this.dados.dataPublicacao &&
            valor < this.menorDataAbertura
          ) {
            this.dados[campo] = null;
            this.valoresMascara[campo] = null;
            this.$alerta('Data de abertura fora do prazo', 'is-danger', 4000);
            return;
          }

          this.$v.dados[campo].$model = valor;
          if (this.dados.horaAbertura) {
            this.dados.dataAbertura.setHours(this.dados.horaAbertura.getHours());
            this.dados.dataAbertura.setMinutes(this.dados.horaAbertura.getMinutes());
          }
        }
        this.$v.dados[campo].$touch();
      }
    },
    mapearFeriadosNacionais() {
      this.feriadosNacionais = this.feriados
        .filter(filtro => filtro.type === 'public')
        .map(feriado => new Date(feriado.date));
    },
    verificarPublicacao() {
      if (this.emPublicacao) this.exibirRequeridos();
    },
  },
  mounted() {
    this.mapearFeriadosNacionais();
  },
  async created() {
    this.loading.general = true;
    try {
      await this.buscarOfertaCompra(this.$route.params.id);
      await this.buscarItens({
        ofertaCompraId: this.$route.params.id,
        listarItensLote: true,
        v2: this.$route.query.v2,
      });
      this.montarOfertaCompra();
      this.verificarPublicacao();
    } catch (error) {
      this.$alerta('Erro ao buscar dados', 'is-danger');
    } finally {
      this.loading.general = false;
    }
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 1024px) {
  .reference-disclose {
    width: 220px;
  }

  .regional-preference {
    width: 300px;
  }
}
</style>
