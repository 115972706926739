function stringToNumber(data) {
  if (typeof data === typeof 'string') {
    const value = data.replace(/[^0-9,]/g, '').replace(',', '.');
    return parseFloat(value);
  }
  return data;
}

function tamanhoArquivo(bytes) {
  const thresh = 1000;
  if (Math.abs(bytes) < thresh) return `${bytes} B`;

  const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let u = -1;
  let bytesRestantes = bytes;
  do {
    bytesRestantes /= thresh;
    u += 1;
  } while (Math.abs(bytesRestantes) >= thresh && u < units.length - 1);
  return `${bytesRestantes.toFixed(1)} ${units[u]}`;
}

function formatarData(data) {
  if (!data || data === '') return '';
  return `${new Date(data).toLocaleDateString('pt-BR')} ${new Date(data).toLocaleTimeString(
    'pt-BR',
    { hour: '2-digit', minute: '2-digit' },
  )}`;
}

function formatarDataSemHorario(data) {
  if (!data || data === '') return '';
  return `${new Date(data).toLocaleDateString('pt-BR')}`;
}

function formatarDataSegundo(data) {
  if (!data || data === '') return '';
  return `${new Date(data).toLocaleDateString('pt-BR')} ${new Date(data).toLocaleTimeString(
    'pt-BR',
    {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    },
  )}`;
}

function setTipoArquivo(nomeArquivo) {
  const extensao = nomeArquivo.split('.');
  return extensao[extensao.length - 1].toUpperCase();
}

function formatarValor(valor, minimoCasasDecimais = 4) {
  return `${(valor ?? 0).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: minimoCasasDecimais,
    maximumFractionDigits: 4,
  })}`;
}

function formatarValorSemPrefixo(valor, minimoCasasDecimais = 4) {
  return `${valor.toLocaleString('pt-BR', {
    minimumFractionDigits: minimoCasasDecimais,
    maximumFractionDigits: 4,
  })}`;
}

function formatarPorcentagem(valor) {
  return `${valor.toLocaleString('pt-BR', {
    style: 'decimal',
    minimumFractionDigits: 4,
    maximumFractionDigits: 4,
  })}`;
}

function formatarPadrao(valor, padrao) {
  return valor || padrao;
}

function formatarNumero(numero) {
  return `${numero.toLocaleString('pt-BR', { style: 'decimal' })}`;
}

function formatarCnpj(valor) {
  return valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
}

function tituloEstadoItem(estado, modoDisputa = 'ABERTO') {
  switch (estado) {
    case 'CADASTRADA':
      return 'Cadastrado';
    case 'LANCE-ENCERRADA':
      if (modoDisputa === 'ABERTO' || modoDisputa === 'FECHADO_ABERTO') {
        return 'Aguardando';
      }
      return 'Finalizado';
    case 'LANCE':
      return 'Iniciado';
    case 'LANCE-RANDOMICO':
      return 'Iniciado';
    case 'LANCE-FECHADO':
      return 'Iniciado';
    case 'PRIMEIRA-CHAMADA':
      return '1ª Chamada';
    case 'NOVA-CHAMADA':
      return 'Nova Chamada';
    case 'PREFERENCIA':
      return 'Preferência';
    case 'PREFERENCIA-INICIADA':
      return 'Iniciada';
    case 'NEGOCIACAO':
      return 'Negociação';
    case 'NEGOCIACAO-ENCERRADA':
      return 'Encerrada';
    case 'HABILITACAO':
      return 'Habilitação';
    case 'HABILITACAO-ENCERRADA':
      return 'Habilitado';
    case 'ADESAO':
      return 'Adesão';
    case 'ADJUDICADO':
      return 'Adjudicado';
    case 'HOMOLOGADO':
      return 'Homologado';
    case 'FRACASSADA':
      return 'Fracassado';
    case 'DESERTO':
      return 'Deserto';
    case 'REVOGADO':
      return 'Revogado';
    case 'ANULADO':
      return 'Anulado';
    default:
      return 'Finalizado';
  }
}

function tituloEstadoItemDispensa(estado) {
  switch (estado) {
    case 'CADASTRADO':
      return 'Cadastrado';
    case 'LANCE':
      return 'Iniciado';
    case 'RATIFICADO':
      return 'Ratificado';
    case 'FRACASSADO':
      return 'Fracassado';
    case 'DESERTO':
      return 'Deserto';
    case 'REVOGADO':
      return 'Revogado';
    case 'ANULADO':
      return 'Anulado';
    default:
      return 'Finalizado';
  }
}

function estadoItem(estado, modoDisputa = 'ABERTO') {
  if (
    [
      'CADASTRADA',
      'PRIMEIRA-CHAMADA',
      'NOVA-CHAMADA',
      'NEGOCIACAO',
      'HABILITACAO',
      'ADESAO',
    ].indexOf(estado) > -1 ||
    (estado === 'LANCE-ENCERRADA' && (modoDisputa === 'ABERTO' || modoDisputa === 'FECHADO_ABERTO'))
  ) {
    return '';
  }
  if (['FRACASSADA', 'DESERTO', 'REVOGADO', 'ANULADO'].indexOf(estado) > -1) return 'is-danger';
  return 'is-success';
}

function estadoItemDispensa(estado) {
  if (estado === 'CADASTRADO') {
    return '';
  }
  if (['FRACASSADO', 'DESERTO', 'REVOGADO'].indexOf(estado) > -1) return 'is-danger';
  return 'is-success';
}

function formatType(type) {
  return {
    MATERIAL: 'MATERIAL',
    SERVICE: 'SERVIÇO',
    LOT: 'LOTE',
  }[type];
}

function truncate(text, length) {
  return text.length > length ? `${text.slice(0, length)}...` : text;
}

export {
  stringToNumber,
  tamanhoArquivo,
  formatarData,
  formatarDataSemHorario,
  formatarDataSegundo,
  formatarValor,
  formatarValorSemPrefixo,
  formatarNumero,
  formatarPorcentagem,
  setTipoArquivo,
  tituloEstadoItem,
  tituloEstadoItemDispensa,
  estadoItem,
  estadoItemDispensa,
  formatarPadrao,
  formatarCnpj,
  formatType,
  truncate,
};
