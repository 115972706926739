import { deletarProposta } from '@/resources/ofertasCompra';
import {
  createProposal,
  deleteProposal,
  fetchProposals,
  importAuctionProposals,
  updateProposal,
  untieProposal,
  classifyItems,
  undoProposalClassification,
} from '@/resources/electronicAuctions/proposals';

const types = {
  AUCTION_PROPOSALS: 'AUCTION_PROPOSALS',
  AUCTION_PROPOSALS_CLEAN: 'AUCTION_PROPOSALS_CLEAN',
  AUCTION_PROPOSALS_IMPORT_RESET_UPLOAD: 'AUCTION_PROPOSALS_IMPORT_RESET_UPLOAD',
  AUCTION_PROPOSALS_IMPORT_UPLOAD: 'AUCTION_PROPOSALS_IMPORT_UPLOAD',
  AUCTION_PROPOSALS_REMOVE: 'AUCTION_PROPOSALS_REMOVE',
  AUCTION_PROPOSALS_UPDATE: 'AUCTION_PROPOSALS_UPDATE',
};

const mutations = {
  [types.AUCTION_PROPOSALS](state, data) {
    if (data && Array.isArray(data)) {
      state.proposals = data;
    } else {
      state.proposals.push(data);
    }
  },
  [types.AUCTION_PROPOSALS_UPDATE](state, data) {
    if (data && Array.isArray(data)) {
      while (data.length) {
        const p = data.shift();
        const index = state.proposals.findIndex(proposal => proposal.id === p.id);
        if (index > -1) {
          state.proposals[index] = Object.assign(state.proposals[index], p);
        }
      }
    } else if (typeof data === 'object') {
      const index = state.proposals.findIndex(proposal => proposal.id === data.id);
      if (index > -1) {
        state.proposals[index] = Object.assign(state.proposals[index], data);
      }
    }
  },
  [types.AUCTION_PROPOSALS_REMOVE](state, data) {
    if (data && Array.isArray(data)) {
      while (data.length) {
        const proposal = data.shift();
        const index = state.proposals.findIndex(proposalItem => proposalItem.id === proposal.id);
        if (index > -1) {
          state.proposals.splice(index, 1);
        }
      }
    } else if (typeof data === 'object') {
      const index = state.proposals.findIndex(proposal => proposal.id === data.id);
      if (index > -1) {
        state.proposals.splice(index, 1);
      }
    }
  },
  [types.AUCTION_PROPOSALS_CLEAN](state) {
    state.proposals = [];
  },
  [types.AUCTION_PROPOSALS_IMPORT_UPLOAD](state, progress) {
    state.auctionImportProposalsUpload = Math.round((progress.loaded / progress.total) * 100);
  },
  [types.AUCTION_PROPOSALS_IMPORT_RESET_UPLOAD](state) {
    state.auctionProposalsImportUpload = 0;
  },
};

const actions = {
  cleanAuctionProposals({ commit }) {
    commit(types.AUCTION_PROPOSALS_CLEAN);
  },
  async createProposal({ commit, getters, dispatch, rootState }, data) {
    const auctionId = rootState.ofertasCompra.ofertaCompra.id;
    const proposal = await createProposal(auctionId, data.ofertaCompraItemId, data);
    commit(types.AUCTION_PROPOSALS, proposal.data);
    if (getters.obterPropostasUsuarioLogado.length === 0) {
      await dispatch('auctionsBidders/fetchUserLoggedBidder', auctionId, { root: true });
      dispatch(
        'auctionsDocuments/fetchAuctionBidderDocuments',
        { auctionId, bidderId: rootState.auctionsBidders.userLoggedBidder.id },
        { root: true },
      );
    }
  },
  async deleteProposal({ commit, dispatch, rootState, getters }, { auctionItemId, proposalId }) {
    const auctionId = rootState.ofertasCompra.ofertaCompra.id;
    const proposal = await deleteProposal(auctionId, auctionItemId, proposalId);
    if (proposal.status === 204) commit(types.AUCTION_PROPOSALS_REMOVE, { id: proposalId });
    if (getters.obterPropostasUsuarioLogado.length === 0) {
      commit('auctionsBidders/CLEAR_USER_LOGGED_BIDDER', null, { root: true });
      dispatch('auctionsDocuments/cleanAuctionDocuments', null, { root: true });
    }
  },
  async deleteProposalByCrier({ commit }, { ofertaCompraId, itemId, propostaId }) {
    const { status, response } = await deletarProposta(ofertaCompraId, itemId, propostaId);
    if (status !== 204) {
      throw new Error(response.message);
    }
    commit(types.AUCTION_PROPOSALS_REMOVE, { id: propostaId });
  },
  async fetchProposals({ commit }, { ofertaCompraId, tipo }) {
    const proposals = await fetchProposals(ofertaCompraId, tipo);
    commit(types.AUCTION_PROPOSALS, proposals.data.data);
    commit('ITEM_PROPOSTAS', proposals.data.data, { root: true });
  },
  async importAuctionProposals({ commit, dispatch }, { auctionId, data }) {
    const response = await importAuctionProposals(auctionId, data, {
      onUploadProgress: progress => commit(types.AUCTION_PROPOSALS_IMPORT_UPLOAD, progress),
    });
    if (response.status !== 201) return Promise.reject(response.data);
    await dispatch('auctionsBidders/fetchUserLoggedBidder', auctionId, { root: true });
    commit(types.AUCTION_PROPOSALS, response.data);
    return commit('ITEM_PROPOSTAS', response.data, { root: true });
  },
  resetAuctionProposalsUpload({ commit }) {
    commit(types.AUCTION_PROPOSALS_IMPORT_RESET_UPLOAD);
  },
  async updateProposal({ commit, rootState }, { propostaId, itemId, proposta }) {
    const auctionId = rootState.ofertasCompra.ofertaCompra.id;
    const response = await updateProposal(auctionId, itemId, propostaId, proposta);
    const {
      status,
      data: { data },
    } = response;
    if (status === 200) commit(types.AUCTION_PROPOSALS_UPDATE, data);
  },
  async undoProposalClassification({ commit, rootState }, { propostaId, itemId }) {
    const auctionId = rootState.ofertasCompra.ofertaCompra.id;
    const response = await undoProposalClassification(auctionId, itemId, propostaId);
    const {
      status,
      data: { data },
    } = response;
    if (status === 200) commit(types.AUCTION_PROPOSALS_UPDATE, data);
  },
  async untieProposal({ commit, rootState }, propostaId) {
    const auctionId = rootState.ofertasCompra.ofertaCompra.id;
    const {
      status,
      data: { data },
    } = await untieProposal(auctionId, propostaId);
    if (status === 200) {
      commit(types.AUCTION_PROPOSALS_UPDATE, data);
      return true;
    }
    return false;
  },
  async classificarItens({ commit, rootState }, { itens, classificacao }) {
    const ofertaCompraId = rootState.ofertasCompra.ofertaCompra.id;
    const response = await classifyItems(ofertaCompraId, { ...classificacao, itens });
    const {
      status,
      data: { data },
    } = response;
    if (status === 200) commit(types.AUCTION_PROPOSALS_UPDATE, data);
  },
};

const getters = {
  obterPropostasItem: state => itemId =>
    state.proposals.filter(proposta => proposta.oferta_compra_item_id === itemId),
  obterPropostasUsuarioLogado(state, _, rootState) {
    const usuarioLogadoFornecedorId = rootState.auctionsBidders.userLoggedBidder.id;
    return state.proposals.filter(proposta => proposta.fornecedor_id === usuarioLogadoFornecedorId);
  },
};

const state = {
  auctionProposalsImportUpload: 0,
  proposals: [],
};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
};

export { types };
