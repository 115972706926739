<template>
  <div>
    <MyItems
      v-if="displayMyItems"
      ref="myitems"
      :loaded="loaded"
      @view-all-items="visualizeAllItems()"
    />
    <AllItems
      v-if="displayAllItems"
      :my-items-filter="displayMyItemsFilter"
      :closed-bid="closedBid"
      :closed-eligible-bidder="closedEligibleBidder"
      :loaded="loaded"
      @view-my-items="visualizeMyItems()"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import auctionItems from '@/mixins/auctionItems';

import MyItems from '@/views/Pregao/AuctionContainer/Items/MyItems';
import AllItems from '@/views/Pregao/AuctionContainer/Items/AllItems';

export default {
  name: 'ItemsWrapper',
  components: {
    MyItems,
    AllItems,
  },
  mixins: [auctionItems],
  props: {
    loaded: Boolean,
  },
  data() {
    return {
      viewAllItems: null,
      viewMyItems: null,
    };
  },
  watch: {
    filteredItems() {
      const itemsInBids = this.allItems.find(
        item => item.estado === 'LANCE' || item.estado === 'PREFERENCIA-INICIADA',
      );
      if (!itemsInBids) {
        this.viewAllItems = null;
        this.viewMyItems = null;
      }
    },
  },
  computed: {
    ...mapGetters({
      allItems: 'obterTodosItens',
      closedEligible: 'obterElegiveisFechado',
      closedParticipants: 'obterParticipantesFechado',
    }),
    ...mapState('auctionsBidders', ['userLoggedBidder']),
    ...mapGetters('proposals', { proposals: 'obterPropostasUsuarioLogado' }),
    closedBid() {
      return this.allItems.some(item => item.estado === 'LANCE-FECHADO');
    },
    closedEligibleBidder() {
      return this.closedEligible.some(
        elegible =>
          elegible.fornecedor_id === this.userLoggedBidder.id &&
          this.allItems.some(
            item => elegible.item_id === item.id && item.estado === 'LANCE-FECHADO',
          ),
      );
    },
    closedModeParticipated() {
      return this.closedParticipants.some(
        participant =>
          participant.empresaId === this.company.id &&
          this.allItems.some(item => participant.itemId === item.id),
      );
    },
    displayAllItems() {
      if (this.viewAllItems === false) return false;

      if (this.viewAllItems === true) return true;

      // Exibe lista para todos se for edital publicado
      if (this.auctionSituation === 2) return true;

      // Exibe lista para visitantes
      if (this.visitor || this.seller || (this.buyer && !this.authority && !this.crier)) {
        return true;
      }

      if (this.auctionSituation === 3 && this.buyer) return true;

      if (
        this.auctionSituation === 3 &&
        !this.exclusiveParticipation &&
        !this.broadNoticeWithQuota &&
        !this.broadNotice
      ) {
        return true;
      }

      // Exibe lista para fornecedores e licitantes
      // Após a situação de envio de propostas
      if (
        (!this.crier && this.auctionSituation === 5) ||
        ([6, 7, 8, 9].indexOf(this.auctionSituation) > -1 && !this.displayMyItemsFilter)
      ) {
        return true;
      }

      if (
        this.auctionSituation === 6 &&
        this.bidder &&
        this.closedBid &&
        !this.closedEligibleBidder
      ) {
        return true;
      }

      if (this.auctionSituation === 9 && this.bidder && this.adherenceStep) return true;

      // Exibe lista todos para pregoeiro diferente de análise de proposta
      if (this.crier && this.auctionSituation !== 5) return true;

      // Exibe a lista para autoridade do pregão
      if (this.authority && this.auctionSituation > 1) return true;

      if ([10, 11, 12, 13, 14, 15, 16, 17, 18].indexOf(this.auctionSituation) > -1) return true;
      return false;
    },
    displayMyItems() {
      if (this.viewMyItems === false) return false;

      if (this.viewMyItems === true) return true;

      // Nao exibe se já está concluída o pregão eletrônico
      if ([10, 12, 13, 15, 16, 17].indexOf(this.auctionSituation) > -1) return false;

      if (
        [6, 7, 8, 9].indexOf(this.auctionSituation) > -1 &&
        this.bidder &&
        this.displayMyItemsFilter &&
        !this.closedBid &&
        !this.adherenceStep
      ) {
        return true;
      }

      if (
        this.auctionSituation === 6 &&
        this.bidder &&
        this.displayMyItemsFilter &&
        this.closedBid &&
        this.closedEligibleBidder &&
        !this.adherenceStep
      ) {
        return true;
      }

      // Exibe a lista para envio de propostas
      // para fornecedores e licitantes
      if ([3, 4].indexOf(this.auctionSituation) > -1 && this.broadParticipation) return true;

      // Exibe a lista para envio de propostas
      // em pregões de participação exclusiva
      if ([3, 4].indexOf(this.auctionSituation) > -1 && this.exclusiveParticipation) return true;

      // Exibe a lista para análise de propostas para o pregoeiro
      if (this.crier && this.auctionSituation === 5) return true;

      return false;
    },
    broadParticipation() {
      return (
        (this.provider || this.bidder) &&
        [3, 4].indexOf(this.auctionSituation) > -1 &&
        !this.exclusiveNotice
      );
    },
    exclusiveParticipation() {
      return (
        (this.provider || this.bidder) &&
        [3, 4].indexOf(this.auctionSituation) > -1 &&
        this.exclusiveNotice &&
        this.company.preference
      );
    },
    broadNotice() {
      return this.auction.tipo_edital === 'AMPLO';
    },
    broadNoticeWithQuota() {
      return this.auction.tipo_edital === 'AMPLOCOTA';
    },
    displayMyItemsFilter() {
      if (!this.bidder) return false;

      switch (this.auctionSituation) {
        case 6:
          return this.allItems
            .filter(item => ['LANCE', 'LANCE-RANDOMICO', 'LANCE-FECHADO'].indexOf(item.estado) > -1)
            .find(item =>
              this.proposals.find(
                proposal =>
                  proposal.oferta_compra_item_id === item.id &&
                  proposal.estado === 'CLASSIFICADA' &&
                  proposal.lancePermitido,
              ),
            );

        case 7:
          return this.allItems
            .filter(item => item.estado === 'PREFERENCIA-INICIADA')
            .find(item => this.userLoggedBidder.alias === item.preferencia.fornecedor);

        case 8:
          if (
            this.auction.modo_disputa === 'ABERTO' ||
            this.auction.modo_disputa === 'FECHADO_ABERTO' ||
            (this.auction.modo_disputa === 'FECHADO' && !this.bidsStep)
          ) {
            return this.allItems
              .filter(item => item.estado === 'NEGOCIACAO')
              .find(item => this.userLoggedBidder.alias === item.menor_lance.fornecedor);
          }
          return this.allItems
            .filter(item => item.estado === 'NEGOCIACAO')
            .find(
              item =>
                this.userLoggedBidder.alias === item.menor_lance.fornecedor &&
                this.closedModeParticipated,
            );

        case 9:
          if (
            this.auction.modo_disputa === 'ABERTO' ||
            this.auction.modo_disputa === 'FECHADO_ABERTO' ||
            (this.auction.modo_disputa === 'FECHADO' && !this.bidsStep)
          ) {
            return this.allItems
              .filter(item => ['NEGOCIACAO', 'HABILITACAO'].indexOf(item.estado) > -1)
              .find(item => this.userLoggedBidder.alias === item.menor_lance.fornecedor);
          }
          return this.allItems
            .filter(item => ['NEGOCIACAO', 'HABILITACAO'].indexOf(item.estado) > -1)
            .find(
              item =>
                this.userLoggedBidder.alias === item.menor_lance.fornecedor &&
                this.closedModeParticipated,
            );

        default:
          return false;
      }
    },
  },
  methods: {
    visualizeAllItems() {
      this.viewAllItems = true;
      this.viewMyItems = false;
    },
    visualizeMyItems() {
      this.viewAllItems = false;
      this.viewMyItems = true;
    },
  },
};
</script>
